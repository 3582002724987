export const PATH_NAME = {
  HOME: "/",
  ABOUT_US: "/about",
  ERROR_404: "/not-found",
  ERROR_500: "/pages/error-500",
  COMING_SOON: "/coming-soon",
  FAQ: "/pages/faq",
  PORTFOLIO: "/portfolio",
  PORTFOLIO_DETAILS: "/portfolio-details",
  TERM_CONDITION: "/terms-condition",
  UNDER_CONDITION: "/under-condition",
  UPGRADE: "/upgrade",
  PRIVACY_POLICY: "/privacy-policy",
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  CHANGE_PASSWORD: "/change-password",
  LOCK_SCREEN: "/pages/lock-screen",
  TEAM: "/teams",
  TEAM_CAROUSEL: "/teams/team-carousel",
  TEAM_DETAILS: "/teams/team-details",

  //task routes
  TASKS: "/tasks",
  ADD_TASK: "/tasks/create",
  CATEGORIES: "/tasks/categories",
  CATEGORIES2: "/tasks/categories-2",
  SERVICE: "/tasks/service",
  SERVICE_DETAILS: "/tasks/service-details",
  SERVICE_GRID_SIDEBAR: "/tasks/service-grid-sidebar",
  SERVICE_SUB_CATEGORY: "/tasks/service-sub-category",
  //blog routes
  BLOG_LIST: "/blog",
  BLOG_GRID: "/blog/blog-grid",
  BLOG_2_GRID: "/blog/blog-2-grid",
  BLOG_CAROUSEL: "/blog/blog-carousel",
  BLOG_DETAILS: "/blog/blog-details",
  BLOG_DETAILS_RIGHT_SIDEBAR: "/blog/blog-details-right-sidebar",
  BLOG_DETAILS_SIDEBAR: "/blog/blog-details-sidebar",
  BLOG_MANSORY: "/blog/blog-mansory",
  BLOG_RIGHT_SIDEBAR: "/blog/blog-right-sidebar",
  BLOG_SIDEBAR: "/blog/blog-sidebar",

  //contact route
  CONTACT_US: "/contact-us",
  CONTACT_US_V2: "/contact/contact-us-v2",
  CONTACT_US_V3: "/contact/contact-us-v3",

  //dashboard
  DASHBOARD: "/dashboard",
  BILLING_SETTING: "/dashboard/settings/billing-setting",
  INTEGRATION_SETTING: "/dashboard/settings/integration-setting",
  NOTIFICATION_SETTING: "/dashboard/settings/notification-setting",
  PREFERENCE_SETTING: "/dashboard/settings/prefence-setting",
  SECURITY_SETTING: "/dashboard/settings/security-setting",
  USER_NOTIFICATION: "/dashboard/settings/notification",
  EDIT_TASK: "/tasks/edit-task",
  USER_FILES: "/dashboard/files",
  USER_TASKS: "/dashboard/manage-tasks",
  USER_MESSAGE: "/dashboard/message",
  USER_PAYMENT: "/dashboard/payment",
  USER_PROFILE: "/dashboard/profile",
  USER_PURCHASE: "/dashboard/purchase",
  USER_REVIEW: "/dashboard/review",
  USER_SALES: "/dashboard/sales",
  USER_SETTINGS: "/dashboard/settings",
  USER_WALLET: "/dashboard/wallet",
  USER_WISHLIST: "/dashboard/wishlist",
  USER_APPLY_TASK: "/dashboard/apply-tasks",
  USER_REQUESTERS: "/dashboard/requesters",
  USER_BALANCE_LOG: "/dashboard/balance-logs",
};
