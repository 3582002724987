"use client";

import Image from "next/image";
import { PATH_NAME } from "@/constants/path-name.constant";
import Link from "next/link";
import { useTranslations } from "next-intl";

const ErrorPage = ({
  errorCode,
  title,
  description,
}: {
  errorCode: number;
  title: string;
  description: string;
}) => {
  const t = useTranslations();
  const routes = PATH_NAME;
  return (
    <div className="error-wrapper">
      <div className="error-item">
        <div className="row w-100">
          {/* 404 Content */}
          <div className="col-md-6 col-sm-8 mx-auto">
            <div className="error-content text-center">
              <div className="error-img d-flex justify-content-center">
                <Image
                  src={`/assets/img/error/error-${errorCode}.png`}
                  className="img-fluid"
                  alt="img"
                  width={581}
                  height={281}
                />
              </div>
              <div className="error-info">
                <h2>{title}</h2>
                <p>{description}</p>
                <Link href={routes.HOME} className="btn btn-primary">
                  {t("text.goBack")}
                  <i className="fa-solid fa-caret-right" />
                </Link>
              </div>
            </div>
          </div>
          {/* /404 Content */}
        </div>
        {/* Error Img */}
        <div className="error-imgs">
          <Image
            src="/assets/img/bg/error-01.png"
            alt="img"
            className="error-01 error-bg"
            width={172}
            height={172}
          />
          <Image
            src="/assets/img/bg/error-01.png"
            alt="img"
            className="error-05 error-bg"
            width={66}
            height={66}
          />
          <Image
            src="/assets/img/bg/error-02.png"
            alt="img"
            className="error-02 error-bg"
            width={100}
            height={100}
          />
          <Image
            src="/assets/img/bg/error-03.png"
            alt="img"
            className="error-03 error-bg"
            width={66}
            height={66}
          />
          <Image
            src="/assets/img/bg/error-04.png"
            alt="img"
            className="error-04 error-bg"
            width={60}
            height={50}
          />
        </div>
        {/* /Error Img */}
      </div>
    </div>
  );
};

export default ErrorPage;
